import React from 'react'
import { useTranslation } from 'react-i18next'
import './Travel.css'

const Travel = () => {
	const { t } = useTranslation()

	const arrowBtn = (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='16'
			fill='currentColor'
			className='bi bi-arrow-right ms-1'
			viewBox='0 0 16 16'
		>
			<path
				fill-rule='evenodd'
				d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8'
			/>
		</svg>
	)
	return (
		<div className='travel' id='booking'>
			<div className='container'>
				<div className='row'>
					<div className='col-xl-5 col-12 mb-5 mb-xl-0'>
						<div className='travel-img-box'>
							<img
								src='./img/travel-img.svg'
								alt='img'
								className='w-100 h-100'
							/>
						</div>
					</div>
					<div className='col-xl-7 col-12 travel-desc-box'>
						<h1 className='travel-title'>
							{t('We are ready to provide the best trip for you')}
						</h1>
						<p className='travel-desc'>
							{t(
								"We have a variety of the world's best destinations that you can choose as your trip destination"
							)}
						</p>
						<button className='hero-content-btn'>
							<a href='#contacts' aria-current='page'>
								{t('Book now')}
								<span className='btn-arrow-icon'>{arrowBtn}</span>
							</a>
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Travel
