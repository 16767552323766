import React from 'react'
import { useTranslation } from 'react-i18next'
import './Footer.css'

const Footer = () => {
	const { t } = useTranslation()
	return (
		<div className='footer'>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-4 col-12 text-xl-start text-center'>
						<img src='./img/footer-logo.svg' alt='logo' />
						<p className='footer-desc text-xl-start text-center'>
							{t(
								'With Shox travel you can experience new travel and the best tourist destinations that we have to offer'
							)}
						</p>
					</div>
					<div className='col-lg-2 col-6 text-xl-start text-center'>
						<a
							className='paycom'
							style={{ textDecoration: 'none' }}
							href='https://my.click.uz/'
							target={'_blank'}
							rel='noreferrer'
						>
							<img src='./img/click-icon.svg' alt='click' />
						</a>
					</div>
					<div className='col-lg-2 col-6 text-xl-start text-center'>
						<a
							className='paycom'
							style={{ textDecoration: 'none' }}
							href='https://payme.uz/home/payment'
							target={'_blank'}
							rel='noreferrer'
						>
							<img src='./img/payme-icon.svg' alt='payme' />
						</a>
					</div>
					<div className='col-lg-4 col-12 ps-4'>
						<h3 className='footer-contact-title text-xl-start text-center'>
							{t('Contact us')}
						</h3>
						<div className='row'>
							<div className='col-12'>
								<p className='contact-us-text'>
									{t(
										'Address: Tashkent city, Yunusabad district, district 14, house 62.'
									)}
								</p>
								<p className='contact-us-text'>
									{t('Working hours: 09:00-1800, Monday-Friday.')}
								</p>
								<p className='contact-us-text'>
									{t('Director: Rakhmatov Shahrukh Ashur son.')}
								</p>
								<p className='contact-us-text'>
									{t('Phone number: +998 94 339 29 33')}
								</p>
								<div className='d-flex align-items-center mb-4 justify-content-center justify-content-xl-start'>
									<a
										className='footer-contact-label'
										href='https://www.instagram.com/shox_travel/'
										target={'_blank'}
										rel='noreferrer'
									>
										<img src='./img/instagram-icon.svg' alt='instagram' />
									</a>
									<a
										className='footer-contact-label'
										href='https://t.me/shox_travel'
										target={'_blank'}
										rel='noreferrer'
									>
										<img src='./img/telegram-icon.svg' alt='telegram' />
									</a>
									<a
										className='footer-contact-label'
										href='mailto:shokhrukh.rakhmatov1234@gmail.com'
										target={'_blank'}
										rel='noreferrer'
									>
										<img src='./img/gmail-icon.svg' alt='gmail' />
									</a>
									<a className='footer-contact-label' href='tel:+998943392933'>
										<img src='./img/phone-icon.svg' alt='phone' />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<p className='footer-desc-bottom'>Copyright © shoxtravel 2024</p>
		</div>
	)
}

export default Footer
